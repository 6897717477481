<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">정산 처리</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                placeholder=""
                @keypress="enterkey()"
                class="inputbox"
                v-model="searchInput"
              />
            </div>
            <div class="search_input_row">
              <div class="p2">정산 주기</div>
              <div class="dateselect">
                <label for="all">
                  <input
                    type="checkbox"
                    name="checkall"
                    value="all"
                    id="checkall"
                    @click="allCheck()"
                    v-model="check"
                  />
                  <em></em>전체</label
                >
                <label for="1">
                  <input
                    type="checkbox"
                    name="chk_lang"
                    id="chk1"
                    value="4"
                    v-model="term"
                    @click="changeFn()"
                  /><em></em>1 개월
                </label>

                <label for="2"
                  ><input
                    type="checkbox"
                    name="chk_lang"
                    id="chk2"
                    value="2"
                    v-model="term"
                    @click="changeFn()"
                  /><em></em>월 2회</label
                >
                <label for="3">
                  <input
                    type="checkbox"
                    name="chk_lang"
                    id="chk3"
                    value="3"
                    v-model="term"
                    @click="changeFn()"
                  /><em></em>월 3회</label
                >
                <label for="4">
                  <input
                    type="checkbox"
                    name="chk_lang"
                    id="chk4"
                    value="1"
                    v-model="term"
                    @click="changeFn()"
                  /><em></em>월 4회</label
                >
                <label for="5">
                  <input
                    type="checkbox"
                    name="chk_lang"
                    id="chk5"
                    value="5"
                    v-model="term"
                    @click="changeFn()"
                  /><em></em>2개월</label
                >
              </div>
            </div>
            <button
              class="btn btn-fill btn-primary btn-block search_btn"
              type="button"
              @click="findPro()"
            >
              검색
            </button>
          </div>

          <!-- <v-data-table
          :headers="PayList"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"></v-data-table> -->
          <div class="div-excelBtn" @click="exportExcel()">
            <div>
              <label class="label-excelBtn">
                <img
                  class="excel"
                  src="~@/assets/imgs/excel/Excel_icon-09.png"
                />

                다운로드</label
              >
            </div>
          </div>

          <div class="div-table">
            <div class="div-row">
              <div
                class="div-cell"
                v-for="(column, index) in TotalColumns"
                :key="index"
                style="background-color: #dee2e6"
              >
                <span>
                  {{ column.msg }}
                </span>
              </div>
            </div>
            <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
            <div
              class="div-row"
              v-for="(item, index) in SiteTotal"
              :key="index"
            >
              <div
                class="div-cell"
                v-for="(column, index) in TotalColumns"
                :key="index"
              >
                <div v-if="column.id == 'site_total'">
                  {{ itemValue(item, column.id) | comma }}
                </div>
                <div v-else>
                  {{ itemValue(item, column.id) | comma | moneyFilter }}
                </div>
              </div>
            </div>
          </div>
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">
                <div>총 {{ searchData.length }} 사이트</div>
                <v-btn
                  v-if="this.permission === 'true'"
                  class="billBtn"
                  style="
                    width: 70px;
                    font-weight: 400;
                    background-color: #3195cf;
                    border: 1px solid #4388c1;
                    color: white;
                    height: 35px;
                    font-size: 13px;
                    display: inline-block;
                    float: right;
                  "
                  @click.prevent="billing()"
                  >일괄 정산</v-btn
                >
              </div>

              <div class="div-table">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="searchData"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  @click:row="getDetail"
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  :page.sync="page"
                >
                  <template v-slot:item.pay_total="{ value }">
                    {{ value | comma | moneyFilter }}
                  </template>
                  <template v-slot:item.settlement_total="{ value }">
                    {{ value | comma | moneyFilter }}
                  </template>
                  <template v-slot:footer>
                    <div class="paging-cover">
                      <b-pagination
                        class="mt-3"
                        v-model="page"
                        :total-rows="searchData.length"
                        :per-page="itemsPerPage"
                      ></b-pagination>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>

            <div v-else-if="tableData.length > 0">
              <div class="countData">
                <div>총 {{ this.tableData.length | comma }} 사이트</div>
                <v-btn
                  v-if="this.permission === 'true'"
                  class="billBtn"
                  style="
                    width: 70px;
                    font-weight: 400;
                    background-color: #3195cf;
                    border: 1px solid #4388c1;
                    color: white;
                    height: 35px;
                    font-size: 13px;
                    display: inline-block;
                    float: right;
                  "
                  @click.prevent="billing()"
                  >일괄 정산</v-btn
                >
              </div>

              <div class="div-table">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="tableData"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  @click:row="getDetail"
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  :page.sync="page"
                >
                  <template v-slot:item.pay_total="{ value }">
                    {{ value | comma | moneyFilter }}
                  </template>
                  <template v-slot:item.settlement_total="{ value }">
                    {{ value | comma | moneyFilter }}
                  </template>
                  <template v-slot:footer>
                    <div class="paging-cover">
                      <b-pagination
                        class="mt-3"
                        v-model="page"
                        :total-rows="tableData.length"
                        :per-page="itemsPerPage"
                      ></b-pagination>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">건물 관리자 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>최초 정산 일자</th>
                  <td>{{ this.site.first_sett }}</td>
                </tr>
                <tr>
                  <th>사이트 ID</th>
                  <td>{{ this.site.id }}</td>
                </tr>
                <tr>
                  <th>사이트명</th>
                  <td>{{ this.site.name }}</td>
                </tr>

                <tr>
                  <th>건물 관리자 ID</th>
                  <td>{{ this.site.provider_email }}</td>
                </tr>
                <tr>
                  <th>건물 관리자 이름</th>
                  <td>{{ this.site.provider_name }}</td>
                </tr>
                <tr>
                  <th>핸드폰 번호</th>
                  <td>{{ this.site.mob_number }}</td>
                </tr>
                <tr>
                  <th>정산 일자</th>
                  <td>{{ this.site.next_sett }}</td>
                </tr>
                <tr>
                  <th>사용된 플러그 수</th>
                  <td>{{ this.site.plug_count }}</td>
                </tr>
                <tr>
                  <th>총 사용 횟수</th>
                  <td>{{ this.site.use_count }}</td>
                </tr>
                <tr>
                  <th>총 사용 시간</th>
                  <td>
                    {{
                      this.site.use_hour === "0시간(null분)"
                        ? "-"
                        : this.site.use_hour
                    }}
                  </td>
                </tr>
                <tr>
                  <th>정산 미반영 수</th>
                  <td>{{ this.site.missed_plug }}</td>
                </tr>
                <tr>
                  <th>결제 총액</th>
                  <td>{{ this.site.pay_total | comma | moneyFilter }}</td>
                </tr>
                <tr>
                  <th>정산 총액</th>
                  <td>{{ this.site.sett_total | comma | moneyFilter }}</td>
                </tr>
                <tr>
                  <th>인증 계좌 정보</th>
                  <td>
                    <table class="table inner_table">
                      <thead>
                        <tr>
                          <th colspan="3">은행정보</th>
                          <th>등록일</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <!-- causing some lags because of null data, WORKS FINE -->

                          <td>
                            {{
                              this.bank_ret ? this.bank_ret[0].bank_name : ""
                            }}
                          </td>
                          <td>
                            {{
                              this.bank_ret ? this.bank_ret[0].acc_holder : ""
                            }}
                          </td>
                          <td>
                            {{ this.bank_ret ? this.bank_ret[0].acc_num : "" }}
                          </td>
                          <td>
                            {{
                              this.bank_ret
                                ? this.bank_ret[0].acc_requested
                                : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>정산 주기</th>
                  <td>
                    {{
                      this.site.settlement_set === 4
                        ? "1개월"
                        : this.site.settlement_set === 3
                        ? "월3회"
                        : this.site.settlement_set === 2
                        ? "월2회"
                        : this.site.settlement_set === 1
                        ? "월4회"
                        : this.site.settlement_set === 5
                        ? "2개월"
                        : 0
                    }}
                    <v-btn
                      style="
                        margin-left: 20px;
                        width: 70px;
                        font-weight: 400;
                        background-color: #3195cf;
                        border: 1px solid #4388c1;
                        color: white;
                        height: 35px;
                        font-size: 13px;
                      "
                      @click.prevent="oneBilling()"
                      >정산</v-btn
                    >
                  </td>
                </tr>
                <tr>
                  <th>세부 내역</th>
                  <!-- 사용 내역 모달창 버튼 -->
                  <td>
                    <div id="User_List" data-app>
                      <v-row justify="center">
                        <v-dialog
                          class="m_dialog"
                          v-model="dialog"
                          persistent
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              v-bind="attrs"
                              v-on="on"
                              class="btn btn-outline btn-wd btn-info uselog_btn"
                            >
                              내역 보기
                            </button>
                          </template>

                          <!-- 모달창 텍스트내용 -->
                          <v-card>
                            <v-card-title class="text-h5">
                              <h2>정산처리 세부 내역</h2>
                            </v-card-title>

                            <!-- 모달창 테이블 -->
                            <div class="div-table">
                              <div class="div-row">
                                <div
                                  class="div-cell"
                                  v-for="(column, index) in paid_tableColumns"
                                  :key="index"
                                >
                                  <span>
                                    {{ column.msg }}
                                  </span>
                                </div>
                              </div>
                              <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                              <div
                                class="div-row"
                                v-for="(item, index) in paid_tableData"
                                :key="index"
                              >
                                <div
                                  class="div-cell"
                                  v-for="(column, index) in paid_tableColumns"
                                  :key="index"
                                >
                                  <div v-if="column.id == 'use_paids'">
                                    <span>
                                      {{
                                        item.use_paid | comma | moneyFilter
                                      }}</span
                                    >
                                  </div>
                                  <div v-else-if="column.id == 'use_pro'">
                                    <span>
                                      {{ item.use_pro | numToTimeFilter }}</span
                                    >
                                  </div>
                                  <div v-else>
                                    {{ itemValue(item, column.id) }}
                                  </div>
                                  <div v-if="column.id == 'use_msg'">
                                    <input
                                      v-model="message"
                                      placeholder="텍스트를 입력하세요."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mijung">미정산</div>
                            <div class="div-table">
                              <div class="div-row">
                                <div
                                  class="div-cell"
                                  v-for="(column, index) in mijung_tableColumns"
                                  :key="index"
                                >
                                  <span>
                                    {{ column.msg }}
                                  </span>
                                </div>
                              </div>
                              <div
                                class="div-row"
                                v-for="(item, index) in mijung_tableData"
                                :key="index"
                              >
                                <div
                                  class="div-cell"
                                  v-for="(column, index) in mijung_tableColumns"
                                  :key="index"
                                >
                                  <div v-if="column.id == 'use_paids'">
                                    <span>
                                      {{
                                        item.use_paid | comma | moneyFilter
                                      }}</span
                                    >
                                  </div>
                                  <div v-if="column.id == 'use_pro'">
                                    <span>
                                      {{ item.use_pro | numToTimeFilter }}</span
                                    >
                                  </div>

                                  <div v-else>
                                    {{ itemValue(item, column.id) }}
                                  </div>
                                  <div v-if="column.id == 'use_msg'">
                                    <input
                                      v-model="message"
                                      placeholder="텍스트를 입력하세요."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- 모달창 테이블 end -->

                            <!-- 모달 안쪽 저장 버튼 -->
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="btn btn-info btn-fill float-right col-1"
                                style="color: white"
                                text
                                @click="dialog = false"
                              >
                                닫기
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- //row -->
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [정산 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import XLSX from "xlsx";

export default {
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 정산 주기 체크박스 체크여부
      check: true,

      // 정신 주기 체크박스 데이터
      term: ["1", "2", "3", "4", "5"],

      // 검색 데이터
      searchInput: "",

      // 인덱스
      index: 0,

      // 내역보기 팝업
      dialog: false,

      change_access: "",

      // 메인 테이블 체크박스 데이터
      selected: [],

      // 사이트 정보
      site: {
        id: "",
        name: "",
        mob_number: "",
        provider_email: "",
        created: "",
        provider_name: "",
        provider_id: "",
        bank_name: "",
        acc_holder: "",
        acc_num: "",
        acc_created: "",
        pay_total: "",
        sett_total: "",
        use_count: "",
        plug_count: "",
        use_hour: "",
        next_sett: "",
        settlement_set: "",
        first_sett: "",
        settlement: "",
        missed_plug: "",
      },

      // 인증계좌 정보
      bank_ret: "",

      // 세부 내역 팝업 데이터
      used_plug_ret: "",

      // 세부내역 미정산 데이터
      missed_plug_ret: "",

      all_plug_ret: "",

      // 페이징 데이터
      totalNum: "",
      page: 1,
      itemsPerPage: 10,

      // 메인 테이블 컬럼
      headers: [
        { text: "사이트 ID", align: "start", sortable: false, value: "id" },
        { text: "사이트명", value: "name" },
        { text: "결제 총액", value: "pay_total" },
        { text: "정산 총액", value: "settlement_total" },
        { text: "정산 일자", value: "next_sett" },
      ],

      // 검색 데이터
      searchData: [],

      // 검색 기준 셀렉트박스 옵션
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "site_name", name: "사이트명" },
        { id: "provider_email", name: "건물 관리자 ID" },
      ],
      // 정산 대상 사이트수 테이블
      TotalColumns: [
        { id: "site_total", msg: "정산 대상 사이트 수" },
        { id: "pay_total", msg: "정산 대상 결제 총액" },
        { id: "sett_total", msg: "정산 대상 정산 총액" },
      ],

      // 정산대상 사이트수, 총액 데이터
      SiteTotal: [{ site_total: 0, pay_total: 0, sett_total: 0 }],

      // 사이트 테이블
      tableColumns: [
        { id: "selbox", msg: "select" },
        { id: "id", msg: "사이트ID" },
        { id: "name", msg: "사이트명" },
        { id: "pay_total", msg: "결제 총액" },
        { id: "settlement_total", msg: "정산 총액" },
        { id: "settlement", msg: "정산 주기" },
      ],
      tableData: [],
      // 모달창 안쪽 내역보기 테이블
      paid_tableColumns: [
        { id: "num", msg: "NO" },
        { id: "plug_id", msg: "플러그 ID" },
        // { id: "plug_name", msg: "플러그 명" },
        { id: "use_mobile", msg: "사용자 핸드폰" },
        { id: "use_start", msg: "시작 시간" },
        { id: "use_end", msg: "종료 시간" },
        { id: "use_pro", msg: "선택 상품" },
        { id: "use_paids", msg: "결제 금액" },
      ],

      // 세부내역 테이블 데이터
      paid_tableData: [],
      // 모달창 안쪽 내역보기 미정산 테이블
      mijung_tableColumns: [
        { id: "num", msg: "NO" },
        { id: "plug_id", msg: "플러그 ID" },
        // { id: "plug_name", msg: "플러그 명" },
        { id: "use_mobile", msg: "사용자 핸드폰" },
        { id: "use_start", msg: "시작 시간" },
        { id: "use_end", msg: "종료 시간" },
        { id: "use_pro", msg: "선택 상품" },
        { id: "use_paids", msg: "결제 금액" },
        { id: "use_comment", msg: "미반영 이유" },
      ],
      mijung_tableData: [],
      clickRowIndex: -1,
      permission: "",
    };
  },
  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          3,
          4
        );
      this.permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }

    // 정산 처리 리스트
    const response = await this.$store.dispatch("FETCH_SETTLEMENT_LIST");
    var list = response.data.data.settlment_list;
    this.totalNum = response.data.data.total;
    for (var item of list) {
      this.SiteTotal[0].site_total += 1;
      this.SiteTotal[0].pay_total += item.total;
      this.SiteTotal[0].sett_total += Math.round(item.total / 1.1);
      var temp = {
        id: item.site_id,
        name: item.name,
        pay_total: item.total === null ? 0 : item.total,
        settlement_total: Math.round(item.total / 1.1),
        settlement: item.sett,
        settlement_set: item.term,
        //bank account ret
        bank_ret: item.bank_ret,
        bank_name: item.bank_name,
        acc_holder: item.acc_holder,
        acc_num: item.acc_num,
        acc_created: item.acc_created,
        //plug usage ret
        use_count: item.use_count,
        plug_count: item.plug_count,
        missed_plug: item.missed_plug,
        use_hour:
          item.use_hour / 60 + "시간" + "(" + (item.use_hour + "분") + ")",
        next_sett: item.next_sett,
        first_sett: item.first_sett,
        //provider info ret
        provider_name: item.provider_name,
        provider_id: item.provider_id,
        provider_email: item.provider_email,
        mob_number: item.mob_number,
        //used plug ret
        used_plug_ret: item.used_plug_ret,
        missed_plug_ret: item.missed_plug_ret,
        all_plug_ret: item.all_plug_ret,
      };
      this.tableData.push(temp);
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.tableData.length,
        listSize = this.itemsPerPage,
        page = Math.floor(listLeng / listSize);
      if (listLeng % listSize > 0) page += 1;

      return page;
    },
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findPro();
      }
    },

    // 정산 처리 검색
    async findPro() {
      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.term = this.term;
      data.check = this.check;
      console.log("term : ", this.term);
      console.log("check : ", this.check);

      console.log(this.searchOptionList.id);
      const response = await this.$store.dispatch("FIND_PAID_PRO", data);
      console.log(response);
      var list = response.data.data.find_paid_pro_list;
      this.totalNum = response.data.data.total;

      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];

      for (var item of list) {
        var temp = {
          id: item.site_id,
          name: item.name,
          pay_total: item.total === null ? 0 : item.total,
          settlement_total: Math.round(item.total / 1.1),
          settlement: item.sett,
          settlement_set: item.term,
          //bank account ret
          bank_ret: item.bank_ret,
          bank_name: item.bank_name,
          acc_holder: item.acc_holder,
          acc_num: item.acc_num,
          acc_created: item.acc_created,
          //plug usage ret
          use_count: item.use_count,
          plug_count: item.plug_count,
          missed_plug: item.missed_plug,
          use_hour:
            item.use_hour / 60 + "시간" + "(" + (item.use_hour + "분") + ")",
          next_sett: item.next_sett,
          first_sett: item.first_sett,
          //provider info ret
          provider_name: item.provider_name,
          provider_email: item.provider_email,
          mob_number: item.mob_number,
          //used plug ret
          used_plug_ret: item.used_plug_ret,
          missed_plug_ret: item.missed_plug_ret,
          all_plug_ret: item.all_plug_ret,
        };
        this.searchData.push(temp);
      }
      this.page = 1;
    },

    // checkAll() {
    //   if ($("#checkall").is(":checked")) {
    //     $("input[type=checkbox]").prop("checked", true);
    //   } else {
    //     $("input[type=checkbox]").prop("checked", false);
    //   }
    // },

    // 정산 주기 체크박스 올체크 기능
    allCheck() {
      if (!this.check) {
        for (let i = 1; i < 6; i++) {
          this.term.push(i);
        }
        this.check = true;
      } else {
        this.term = [];

        this.check = false;
      }
      this.$emit("checkClick", this.term);
    },

    // 개별 체크 기능
    changeFn() {
      if ($("#chk1").prop("checked")) {
        $("#chk1").prop("checked", true);

        $("#checkall").prop("checked", false);
      }
      if ($("#chk2").prop("checked")) {
        $("#chk2").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
      if ($("#chk3").prop("checked")) {
        $("#chk3").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
      if ($("#chk4").prop("checked")) {
        $("#chk4").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
      if ($("#chk5").prop("checked")) {
        $("#chk5").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
    },

    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    // 메인 테이블 상세정보
    getDetail(row) {
      this.clickRowIndex = row.id;
      // it.select(true);
      this.tableData.map((item, index) => {
        item.selected = item === row;
        this.$set(this.tableData, index, item);
      });
      this.site.id = row.id;
      this.site.name = row.name;
      this.site.provider_email = row.provider_email;
      this.site.provider_name = row.provider_name;
      this.site.provider_id = row.provider_id;
      this.site.mob_number = row.mob_number;
      this.site.pay_total = row.pay_total;
      this.site.sett_total = row.settlement_total;
      this.site.use_count = row.use_count;
      this.site.plug_count = row.plug_count;
      this.site.use_hour = row.use_hour;
      this.site.next_sett = row.next_sett;
      this.site.settlement_set = row.settlement_set;
      this.site.first_sett = row.first_sett;
      this.bank_ret = row.bank_ret;
      this.site.settlement = row.settlement;
      this.site.missed_plug = row.missed_plug_ret.length;
      this.all_plug_ret = row.all_plug_ret;
      this.used_plug_ret = row.used_plug_ret;
      this.paid_tableData = [];
      for (var i = 0; i < this.used_plug_ret.length; i++) {
        this.paid_tableData.push({
          num: this.used_plug_ret.length - i,
          plug_id: this.used_plug_ret[i].plug_id,
          plug_name: this.used_plug_ret[i].plug_name,
          use_mobile: this.used_plug_ret[i].mobile_number,
          use_start: this.$getDateYYYYMMDD(this.used_plug_ret[i].time_from),
          use_end: this.$getDateYYYYMMDD(this.used_plug_ret[i].time_to),
          use_pro: this.used_plug_ret[i].minute / 60,
          use_paid: this.used_plug_ret[i].charge,
        });
      }
      this.missed_plug_ret = row.missed_plug_ret;
      this.mijung_tableData = [];
      for (var i = 0; i < this.missed_plug_ret.length; i++) {
        this.mijung_tableData.push({
          num: this.missed_plug_ret.length - i,
          plug_id: this.missed_plug_ret[i].plug_id,
          plug_name: this.missed_plug_ret[i].plug_name,
          use_mobile: this.missed_plug_ret[i].mobile_number,
          use_start: this.$getDateYYYYMMDD(this.missed_plug_ret[i].time_from),
          use_end: this.$getDateYYYYMMDD(this.missed_plug_ret[i].time_to),
          use_pro: this.missed_plug_ret[i].minute / 60,
          use_paid:
            this.missed_plug_ret[i].bill_status === "1"
              ? "-" + this.missed_plug_ret[i].charge
              : this.missed_plug_ret[i].charge,
          use_comment: this.missed_plug_ret[i].bill_comment,
          //add column with the comment?
        });
      }
    },

    // 일괄 정산 기능
    async billing() {
      console.log(this.selected);
      var data = this.selected;

      if (this.selected.length === 0) {
        alert("선택이 되어 있지 않습니다.");
      } else {
        if (confirm("정산 처리하는 것이 맞습니까?") == true) {
          const response = await this.$store.dispatch(
            "FETCH_CREATE_BILLING",
            data
          );
          if (response.data.result === true) {
            alert("정상적으로 정산 처리 됐습니다");
            window.location.reload();
            console.log(response);
          } else {
            alert("정상적으로 처리되지 않았습니다. 개발팀에 문의하세요.1");
            console.log(response);
          }
        } else {
          return false;
        }
      }
    },

    // 정산기능
    async oneBilling() {
      var data = {};
      data.id = this.site.id;
      data.name = this.site.name;
      data.settlement_total = this.site.sett_total;
      data.bank_ret = this.bank_ret;
      data.pay_total = this.site.pay_total;
      data.settlement_set = this.site.settlement_set;
      data.settlement = this.site.settlement;
      data.missed_plug = this.site.missed_plug;
      data.use_count = this.site.use_count;
      data.all_plug_ret = this.all_plug_ret;
      data.provider_name = this.site.provider_name;
      data.provider_id = this.site.provider_id;
      data.next_sett = this.site.next_sett;

      var info = [data];

      if (confirm("정산 처리하는 것이 맞습니까?") === true) {
        const response = await this.$store.dispatch(
          "FETCH_CREATE_BILLING",
          info
        );
        if (response.data.result === true) {
          alert("정상적으로 정산 처리 됬습니다");
          window.location.reload();
          console.log(response);
        } else {
          alert("정상적으로 처리되지 않았습니다. 개발팀에 문의하세요.2");
          console.log(response);
        }
      } else {
        return false;
      }
    },

    // 엑셀 다운로그 기능
    exportExcel() {
      var today = new Date();
      var today_date =
        today.getFullYear() +
        "" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "" +
        ("0" + today.getDate()).slice(-2);
      var today_time =
        ("0" + today.getHours()).slice(-2) +
        "" +
        ("0" + today.getMinutes()).slice(-2) +
        "" +
        ("0" + today.getSeconds()).slice(-2);

      const wb = XLSX.utils.book_new(); //엑셀 파일 생성(workbook)
      var ws_data = [
        ["추출일", this.$getDateYYYYMMDD(today_date + today_time)],
        [],
      ];

      //header setting
      var temp = [];
      var i = 0;
      if (this.searchData.length > 0) {
        for (var header of this.tableColumns) {
          if (header.id == "selbox") {
            continue;
          }
          temp[i] = header.msg;
          i++;
        }
        ws_data.push(temp);

        //data setting
        for (var row of this.searchData) {
          temp = [];
          i = 0;
          for (var header of this.tableColumns) {
            if (header.id == "selbox") {
              continue;
            }
            temp[i] = this.itemValue(row, header.id);
            i++;
          }
          ws_data.push(temp);
        }
      } else {
        for (var header of this.tableColumns) {
          if (header.id == "selbox") {
            continue;
          }
          temp[i] = header.msg;
          i++;
        }
        ws_data.push(temp);

        //data setting
        for (var row of this.tableData) {
          temp = [];
          i = 0;
          for (var header of this.tableColumns) {
            if (header.id == "selbox") {
              continue;
            }
            temp[i] = this.itemValue(row, header.id);
            i++;
          }
          ws_data.push(temp);
        }
      }

      /*
      for(var row of this.tableData){ //data setting
        for(var header of this.tableColumns){
          if(header.id == 'selbox') { continue; }
          temp[ header.msg+''] =  this.itemValue(row, header.id) ;
          data.push(temp);
        }
      }
      const ws = XLSX.utils.json_to_sheet(data); //시트 생성 및 데이터 삽입
      XLSX.utils.book_append_sheet(wb,ws,'Sheet1'); //엑셀 파일에 시트 추가
      */

      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      const workseet = XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      //엑셀 파일 명
      var fileName =
        "정산 검색_" + today_date + "_" + today_time + "_" + ".xlsx";
      XLSX.writeFile(wb, fileName); //엑셀 다운로드
    },
  },
};
</script>
<style scoped>
.card {
  height: 100%;
}
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  display: flex;
  margin: 20px 0 0 0;
}
.search_div .search_btn {
  width: 300px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
  margin: auto;
}
.radio_div {
  display: flex;
  white-space: nowrap;
}
.radio_div div {
  margin-right: 30px;
}
.countData {
  padding: 15px;
  display: block;
}

.countData > div {
  display: inline-block;
  line-height: 2.3;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
  width: 10rem; /*테이블 모양이 안 바뀌어*/
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
  margin-top: 5px;
  height: 40px;
  margin-left: 5px;
}
.mijung {
  padding: 70px 0px 15px 25px;
  font-size: 20px;
}
.ttext {
  margin: 50px 0 0 20px;
}
.tbtn {
  margin-top: 50px;
}
.excel {
  height: 23px;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.p2 {
  margin: 13px 5px 0 0;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}
.dateselect {
  margin: 15px 0 30px 30px;
}
label {
  margin-left: 25px;
  font-size: 16px;
}
em {
  margin-right: 10px;
}
</style>
